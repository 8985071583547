<template>
    <div class="" style="background-color: #FFFFFF; padding:25px;">
        <div class="content-area">
            <div class="container-fluid">
                <div class="claimlinq-notice-for-repairer">
                    <!--eslint-disable-->
                    <!-- <div class="text-center ma-2">
                        <div class="claimlinq-logo">Claimlinq</div>
                    </div> -->

                    <div class="claimlinq-notice-for-repairer-header">
                        <div class="logo">
                            <h1 class="claimlinq-logo text-center" :class="{'claimlinq-logo--large': !expired && !submitted}">ClaimLinq</h1>
                        </div>
                        <span class="close" @click.prevent="clickCancel">×</span>
                    </div>

                    <div v-if="expired" class="vh-center">
                        <div class="mx-auto text-center subheader font-14 font-nunito--bold">This page has expired and no longer available</div>
                    </div>
                    <div v-else class="page-body claimlinq-notice-for-repairer-body">

                        <div class="text-center ma-2">
                            <img v-if="companyLogo" :src="companyLogo" alt="Company Logo" style="max-width: 400px; max-height: 200px">
                        </div>

                        <div v-if="submitted">
                            <div class="mx-auto text-center subheader font-14 font-nunito--bold vh-center">Submitted successfully</div>
                        </div>
                        <good-wizard
                                v-else
                                :steps="steps"
                                :currentStep="step"
                                :ref="'noticeForRepairerStepper'"
                                :onBack="() => step -= 1"
                                :onNext="() => step += 1"
                                :isSticky="false"
                        >
                            <div slot="notice-for-repairer-step-1" class="notice-for-repairer-step-1">
                                <div v-if="expired">
                                    <div class="mx-auto text-center subheader font-14 font-nunito--bold">This page has expired and no longer available</div>
                                </div>
                                <div v-else>
                                    <div class="subheader font-14 font-nunito--bold">Review and fill in the details of the vehicle for the damage repair assessment.</div>
                                    <div class="claim-block bg-white">
                                        <div class="form-group row">
                                            <label class="col-sm-5 col-5 col-form-label">Assessment Number</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" readonly :value="assessment.number"/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-5 col-5 col-form-label">Vehicle Owner</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" readonly :value="vehicle.owner"/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-5 col-5 col-form-label">Rego</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" readonly :value="vehicle.rego"/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-5 col-5 col-form-label">Make</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" readonly :value="vehicle.make"/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-5 col-5 col-form-label">Model</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" readonly :value="vehicle.model"/>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="notice.isSetDateVehicleIn">
                                            <label class="col-sm-5 col-5 col-form-label">Date in for Repairer</label>
                                            <div class="col-sm-7 col-7">
                                                <b-form-datepicker
                                                        v-model="vehicle.dateVehicleIn"
                                                        class="mb-2 "
                                                        locale="en-GB"
                                                        :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                                                ></b-form-datepicker>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="notice.isSetVehicleBody">
                                            <label class="col-sm-5 col-5 col-form-label">Body</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" v-model="vehicle.body"/>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="notice.isSetVehicleDom">
                                            <label class="col-5 col-form-label">Date of Manufacture</label>
                                            <div class="col-7">
                                                <input type="text" class="form-control" v-model="vehicle.dom"/>
                                            </div>
                                        </div>

                                        <div class="form-group row" v-if="notice.isSetVehicleVin">
                                            <label class="col-sm-5 col-5 col-form-label">VIN</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" v-model="vehicle.vin"/>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="notice.isSetVehiclePaintCode">
                                            <label class="col-sm-5 col-5 col-form-label">Paint Code</label>
                                            <div class="col-sm-7 col-7">
                                                <input type="text" class="form-control" v-model="vehicle.color"/>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="notice.isSetVehicleOdometer">
                                            <label class="col-sm-5 col-5 col-form-label">Odometer</label>
                                            <div class="col-sm-7 col-7">
                                                <number-formatter v-model="vehicle.odometer" class="form-control numeric" format="0"></number-formatter>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="notice.isSetVehicleTransmission">
                                            <label class="col-sm-5 col-5 col-form-label">Transmission</label>
                                            <div class="col-sm-7 col-7">
                                                <multiselect
                                                        v-model="vehicle.transmission"
                                                        :options="['Automatic', 'Manual']"
                                                        :showLabels="false"
                                                        :option-height="29"
                                                        :max-height="203"
                                                        :close-on-select="true"
                                                        :allow-empty="false"
                                                        placeholder="Select Transmission"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div slot="notice-for-repairer-step-2" class="notice-for-repairer-step-2">
                                <div class="mb-4">
                                    <div class="font-nunito font-nunito--bold">Review and Upload images of the Vehicle damages.</div>
                                    <br>

                                    <template v-if="notice.isSetViewUploadImages">
                                        <div class="font-nunito font-nunito--bold pl-2">Upload New Photos</div>

                                        <file-upload
                                                class="w-100"
                                                type="image"
                                                :objectId="0"
                                                :value="[]"
                                                :config="{maxFilesize: 100, thumbnailWidth: 200, timeout: 300000, addRemoveLinks: true, resizeWidth: 1500, resizeQuality: 0.7, acceptedFiles: 'image/jpeg, image/jpg, image/png', }"
                                                :api-urls="uploadUrls"
                                                :files-accepted="'image/png, image/jpeg'"
                                                :dynamic-width="true"
                                                :after-upload-function-override="afterUploadImage"
                                                :after-upload="afterUploadImageEmitted"
                                        />

                                        <br>
                                        <div class="subheader">
                                            Choose the images to submit by ticking the box next to the image.
                                        </div>
                                        <div class="notice-for-repairer notice-for-repairer-images">
                                            <image-selector :images="images" :path="''" :props-selected-images="selectedImages" @onChange="onImageSelect"></image-selector>
                                        </div>
                                        <!--                                        <div class="font-nunito font-nunito&#45;&#45;bold pl-2 mb-2">-->
                                        <!--                                            New Photos-->
                                        <!--                                        </div>-->
                                        <!--                                        <div class="container-fluid">-->
                                        <!--                                            <div class="row no-gutter">-->
                                        <!--                                                <div class="col-4 p-2 overflow-hidden" v-for="i of images">-->
                                        <!--                                                    <b-aspect aspect="16:9">-->
                                        <!--                                                        <div class="w-100 border rounded overflow-hidden bg-light h-20 overflow-hidden">-->
                                        <!--                                                            <img class="img-fluid w-100 h-100 object-fit-cover" :src="i.url" alt="">-->
                                        <!--                                                            <a-->
                                        <!--                                                               href="#"-->
                                        <!--                                                               class="control-elem-1__item"-->
                                        <!--                                                               @click.prevent="onClickRemove(index, img)"-->
                                        <!--                                                               v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Remove this image', placement:'leftbottom'}">-->
                                        <!--                                                                <i class="bx bx-trash"></i>-->
                                        <!--                                                            </a>-->
                                        <!--                                                        </div>-->
                                        <!--                                                    </b-aspect>-->
                                        <!--                                                </div>-->


                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                        <!--                                        <br>-->
                                    </template>

                                    <template v-if="notice.isSetViewExistingImages">
                                        <div class="font-nunito font-nunito--bold pl-2 mb-2">
                                            Existing Photos
                                        </div>
                                        <div class="container-fluid">
                                            <div class="row no-gutter">
                                                <div class="col-4 p-2 overflow-hidden" v-for="image of existedImages">
                                                    <b-aspect aspect="16:9">
                                                        <div class="w-100 border rounded overflow-hidden bg-light h-20 overflow-hidden">
                                                            <img class="img-fluid w-100 h-100 object-fit-cover" :src="image.url" alt="">
                                                        </div>
                                                    </b-aspect>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div slot="notice-for-repairer-step-3" class="notice-for-repairer-step-3">
                                <div class="mb-4">
                                    <div class="subheader font-14 font-nunito--bold">Review and Upload new files.</div>

                                    <template v-if="notice.isSetViewUploadFiles">
                                        <file-upload
                                                class="w-100 mb-4"
                                                type="file"
                                                :api-urls="uploadUrls"
                                                :files-accepted="'application/pdf, image/jpeg'"
                                                :value="[]"
                                                :object-id="0"
                                                :dynamic-width="true"
                                                :after-upload-function-override="afterUploadFile"
                                                :after-upload="afterUploadFileEmitted"
                                        />
                                        <div class="subheader">
                                            Tick the Files to be added as shown below
                                        </div>
                                        <div class="notice-for-repairer notice-for-repairer-files">
                                            <div class="box box-block">
                                                <div class="row">
                                                    <table class="table b-table select-parts">
                                                        <thead class="bg-navyblue">
                                                        <tr>
                                                            <th colspan="3" class="header-check">
                                                                <label class="form-check-inline checkbox">
                                                                    <input @click="toggleSelectAllFiles" class="form-check-input" type="checkbox" v-model="allFilesSelected"
                                                                           :checked="allFilesSelected">
                                                                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Select All</span>
                                                                </label>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="file in files" v-bind:key="file.id">
                                                            <td class="file-checkbox">
                                                                <label class="form-check-inline checkbox">
                                                                    <input class="form-check-input" type="checkbox" v-model="file.selected">
                                                                    <span class="icon"><i class='bx bx-check'></i></span>
                                                                </label>
                                                            </td>
                                                            <td class="file-name">{{ file.name }}</td>
                                                            <td class="file-status">
                                                                <strong>Status:</strong>
                                                                <span v-show="!file.selected" class="text-muted">Not attached</span>
                                                                <strong v-show="file.selected">To be attached</strong>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>

                                        <!--                                        <div class="font-nunito font-nunito&#45;&#45;bold pl-2 mb-2">New Files</div>-->
                                        <!--                                        <div class="box box-block">-->
                                        <!--                                            <table class="table b-table select-parts">-->
                                        <!--                                                <thead class="bg-navyblue"></thead>-->
                                        <!--                                                <tbody>-->
                                        <!--                                                <tr v-for="(file, index) of files" :key="index">-->
                                        <!--                                                    <td class="file-name">{{ file.name }}</td>-->
                                        <!--                                                    <td class="file-status">-->
                                        <!--                                                        1.8 MB-->
                                        <!--                                                    </td>-->
                                        <!--                                                </tr>-->
                                        <!--                                                </tbody>-->
                                        <!--                                            </table>-->
                                        <!--                                        </div>-->
                                    </template>

                                    <template v-if="notice.isSetViewExistingFiles">
                                        <div class="font-nunito font-nunito--bold pl-2 mb-2">Existing Files</div>
                                        <table class="table b-table select-parts">
                                            <thead class="bg-navyblue"></thead>
                                            <tbody>
                                            <tr v-for="(file, index) of existedFiles" :key="index">
                                                <td class="file-name">{{ file.file_name }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </template>

                                    <div class="form-group row" v-if="notice.isSetCommentOnDamages">
                                        <label class="col-12 col-form-label">Comment on Damages</label>
                                        <div class="col-12">
                                            <textarea v-model="vehicle.commentOnDamages" class="form-control" rows="8"></textarea>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-5 col-form-label">Date of Submission</label>
                                        <div class="col-7">
                                            <b-form-datepicker
                                                    v-model="currentDate"
                                                    class="mb-2 "
                                                    locale="en-GB"
                                                    :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                                                    readonly
                                                    disabled
                                            ></b-form-datepicker>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-5 col-form-label">Name of Submitter</label>
                                        <div class="col-7">
                                            <input type="text" v-model="notice.submitterName" class="form-control" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </good-wizard>

                        <div v-if="!expired && !submitted" class="wizard__buttons">
                            <button v-if="step > 1"
                                    class="btn btn-outline-primary button-back"
                                    type="button"
                                    @click="$refs.noticeForRepairerStepper.goBack()">
                                Back
                            </button>
                            <div v-else>&nbsp;</div>

                            <!--                            <button class="btn btn-outline-primary button-cancel"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="clickCancel">-->
                            <!--                                Cancel-->
                            <!--                            </button>-->
                            <button v-if="step < 3"
                                    class="btn btn-primary button-next"
                                    type="button"
                                    @click="$refs.noticeForRepairerStepper.goNext()"
                                    :tabindex="4"
                            >
                                Next
                            </button>
                            <button v-if="step >= 3"
                                    class="btn btn-primary button-claim"
                                    type="button"
                                    @click="onSubmit"
                                    :disabled="isLoading"
                            >
                                Submit
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GoodWizard from "@/components/vue-good-wizard";
    import ImageSelector from "@/components/image-selector";
    import FileUpload from "@/components/FileUpload";
    import Axios from "axios";
    import NumberFormatter from '@/components/number-formatter';
    import multiselect from "vue-multiselect";
    export default {
        name: "notice-for-repairer",
        components: {
            GoodWizard,
            FileUpload,
            ImageSelector,
            NumberFormatter,
            multiselect,
        },
        data() {
            return {
                expired: false,
                submitted: false,
                step: 1,
                steps: [
                    {
                        label: "Vehicle Details",
                        slot: "notice-for-repairer-step-1"
                    },
                    {
                        label: "Images",
                        slot: "notice-for-repairer-step-2",
                    },
                    {
                        label: "Files",
                        slot: "notice-for-repairer-step-3",
                    }
                ],
                assessment: {
                    id: '',
                    number: '',
                    manual: {}
                },
                vehicle: {
                    rego: '',
                    make: '',
                    model: '',
                    owner: '',
                    vin: '',
                },
                notice: {
                    hash: null,
                    isSetDateVehicleIn: false,
                    isSetVehicleBody: false,
                    isSetVehicleDom: false,
                    isSetVehicleVin: false,
                    isSetVehiclePaintCode: false,
                    isSetVehicleOdometer: false,
                    isSetVehicleTransmission: false,
                    isSetViewExistingImages: false,
                    isSetViewUploadImages: false,
                    isSetViewExistingFiles: false,
                    isSetViewUploadFiles: false,
                    isSetCommentOnDamages: false,
                },

                // input
                vehicleDetails: {},
                allFilesSelected: false,
                images: [],
                existedImages: [],
                selectedImages: [],
                existedFiles: [],
                files: [],
                assessmentId: 0,
                isLoading: false,
                companyLogo: null,
            };
        },
        computed: {
            repairerId() {
                return this.$route.params.repairerId;
            },
            uploadUrls() {
                return {uploadFile: `/efr/file/upload/` + this.$route.params.hash};
            },
            currentDate() {
                let d = new Date();
                return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
            },
        },
        mounted() {
            this.loadForm(this.$route.params.hash);
        },
        methods: {
            loadForm(hash) {
                Axios.get(`/efr/repairer/${hash}`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.assessment.number = response.data.assessmentNumber;
                            this.vehicle = response.data.vehicle;
                            this.companyLogo = response.data.companyLogo;
                            this.existedImages = response.data.images;
                            this.existedFiles = response.data.files;
                            this.notice = response.data.sentToRepairer;
                            console.log(response.data);
                        } else {
                            this.expired = true;
                            console.error(response.data);
                        }
                    })
                    .catch(e => console.error(e));
            },

            clickCancel: function () {

            },

            // composables
            afterUploadImage(file, data) {
                if (data && file && data._status && data.fileId > 0) {
                    this.images.push({
                        url: file.dataURL,
                        src: file.dataURL,
                        id: data.fileId,
                    });
                }
            },
            afterUploadImageEmitted() {
            },
            afterUploadFile(file, data) {
                if (data && file && data._status && data.fileId > 0) {
                    this.files.push({
                        name: file.name,
                        selected: false,
                        id: data.fileId,
                    });
                }
            },
            afterUploadFileEmitted() {
            },
            toggleSelectAllFiles() {
                if (this.allFilesSelected) {
                    this.files.forEach(file => file.selected = false);
                } else {
                    this.files.forEach(file => file.selected = true);
                }
            },
            onImageSelect: function (imgs) {
                this.selectedImages = imgs;
            },
            onSubmit() {
                this.isSending = true;
                let files = [];
                this.files.forEach(f => {
                    if (!f.selected) return;
                    files.push(f.id);
                });

                Axios.post(`/efr/repairer/${this.$route.params.hash}`, {
                    dateVehicleIn: this.vehicle.dateVehicleIn,
                    vehicleBody: this.vehicle.body,
                    vehicleDom: this.vehicle.dom,
                    vehicleVin: this.vehicle.vin,
                    vehiclePaintCode: this.vehicle.color,
                    vehicleOdometer: this.vehicle.odometer,
                    vehicleTransmission: this.vehicle.transmission,
                    commentOnDamages: this.vehicle.commentOnDamages,
                    submittedBy: this.notice.submitterName,
                    images: this.selectedImages,
                    files: files,
                })
                    .then(response => {
                        console.log(response.data);
                        if (response.data && response.data._status) {
                            this.submitted = true;
                        }
                    })
                    .catch(e => console.error(e));
            }
        }
    };
</script>

<style lang="scss" scoped>
    .claimlinq-notice-for-repairer {
        max-width: 900px;
        margin: 0 auto;
    }

    .form-control[readonly=readonly] {
        border-color: transparent;
    }

    .form-control[readonly=readonly]:focus {
        box-shadow: none;
    }

    /* grey select */
    .theme-claimlinq label.checkbox > span.icon.icon-bg-grey {
        background-color: #1B1E381A;
    }

    .theme-claimlinq label.checkbox > span.icon.icon-bg-grey .bx {
        color: transparent;
    }

    .V3 .wizard__buttons {
        display: flex;
        position: relative;
        flex-flow: row wrap;
        justify-content: flex-end;
        max-width: 573px;
        margin: 0 auto;
    }

    .V3 .wizard__buttons .btn {
        flex: 0 1 120px;
        margin-left: 15px;
        margin-bottom: 15px;
        min-width: 82px;
        padding: 10px;
    }

    .V3 .wizard__buttons .btn:first-child {
        margin-right: auto;
        margin-left: 0px;
    }

    .V3 .wizard__buttons .btn:last-child {
        margin-right: 0;
    }

    .V3 .wizard__buttons .button-cancel {
        border-color: rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons > div:first-child + .btn {
        margin-right: 15px;
    }

    .V3 .wizard__buttons .button-cancel:hover,
    .V3 .wizard__buttons .button-cancel:focus {
        background-color: rgba(28, 31, 57, 0.62);
        color: white;
    }

    @media screen and (max-width: 625px) {
        .V3 .wizard__buttons {
            padding: 0 15px;
            width: 100%;
        }

        .V3 .wizard__buttons .btn {
            flex-grow: 1;
            font-size: 13px;
            font-weight: bold;
            line-height: 18px;
            height: 39px;
        }

        .V3 .wizard__buttons .button-claim-estimate {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 320px) {
        .V3 .wizard__buttons .btn:nth-child(3) {
            margin-left: 0px;
        }
    }

    .control-elem-1 {
        position: absolute;
        top: 5px;
        right: 5px;
        /*width: 90px;*/
        height: 40px;
        border-radius: 3px;
        background-color: #FFFFFF;
        opacity: 0;
        transition: opacity 150ms ease-in;

        &__item {
            display: block;
            box-sizing: border-box;
            padding: 10px 13px;
        }

        &__item:hover {
            cursor: pointer;
        }

        &__item--bordered {
            border-right: 1px solid rgba(27, 30, 56, 0.1);
        }
    }

    .control-elem-1 span {
        width: 1px;
        border-left: 1px rgba(27, 30, 56, 0.1) solid;
        height: 40px;
        margin-left: 12px;
        display: inline-block;
        cursor: pointer;
    }

    .eye:hover g {
        fill: #00B050;
    }

    .V3 .image-lightbox {
        margin-top: 15px;
    }

    .pin_images {
        .box {
            margin-right: 0;
        }
    }

    .img-box-container:nth-child(3n+3) .img-box {
        margin-right: 0;
    }

    .hand-pointer {
        cursor: pointer;
    }

    .V3 .below_drag_area {
        width: 100%;
        white-space: nowrap;
        overflow: scroll;
    }

    .V3 .below_drag_area div.box {
        text-align: center;
    }

    .V3 .below_drag_area div.box img {
        width: 100%;
        border-radius: 3px;
    }

    .V3 .below_drag_area .img-box {
        position: relative;
        width: 100%;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a {
        display: inline-block;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .V3 .below_drag_area .img-box,
    .img-box {
        border-radius: 3px;
        overflow: hidden;

        &__a {
            display: block;
            height: 169px;
        }
    }

    .V3 .below_drag_area .img-box:hover > .control-elem-1,
    .img-box:hover > .control-elem-1 {
        opacity: 1;
    }

    .V3 .below_drag_area .each_row_images > div .item_div_drag {
        width: 100%;
    }

    @media screen and (max-width: 992px) and (min-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            /* width: 33.3%; */
        }
    }

    // tablet
    @media screen and (max-width: 768px) {
        .img-box__a {
            height: 130px;
        }
    }

    @media screen and (max-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            width: 100%;
        }
        .img-box-container .img-box {
            // margin-right: 0;
        }
        .img-box__a {
            height: 70px;
        }
    }

    /* extra small device */
    @media screen and (max-width: 350px) {
        .img-box__a {
            height: 18vw;
        }
        .box {
            margin-right: 2.5vw;
            margin-bottom: 2.5vw;
        }
    }

    .V3 .below_drag_area .each_row_images {
        margin: 15px 0;
        padding: 0 15px;
        border-right: 1px solid lightgray;
        width: 283px;
        display: inline-block;
        text-align: center;
    }

    .V3 .each_row_images:last-child {
        border-right: none;
    }

    .V3 .below_drag_area .categories_title {
        height: 3rem;
        text-align: start;
        padding-top: 10px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.1);
    }

    .V3 .below_drag_area .area-empty-images {
        opacity: 0.25;
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        margin: auto;
    }

    .V3 .below_drag_area .hidden-area-empty-images {
        display: none;
    }

    .V3 .below_drag_area .no_images_drag_area {
        height: 30rem;
        margin: 1rem 0;
        text-align: center;
        padding-top: 82px;
    }

    .V3 .below_drag_area .draggable-images {
        width: 100%;
        height: 30rem;
        overflow: scroll;
        margin: 1rem 0;
    }

    .V3 .below_drag_area .text-title-categories {
        margin: 1rem;
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
    }

    .V3 .below_drag_area .icon-action-block {
        position: absolute;
        height: 40px;
        width: 90px;
        border-radius: 3px;
        background-color: #FFFFFF;
        top: 5px;
        right: 5px;
    }

    .V3 .below_drag_area .icon-action-block div {
        cursor: pointer;
    }

    .V3 .below_drag_area .icon-action-block i {
        font-size: 20px;
        font-weight: bold;
    }

    .V3 .below_drag_area .action-button {
        float: left;
        width: 50%;
        height: 100%;
        padding-top: 9px;
    }

    .V3 .below_drag_area .action-button:first-child {
        border-right: 1px solid rgba(27, 30, 56, 0.1);;
    }

    .V3 .pin_images {
        .image-lightbox {
            width: 100%;
            margin-right: 0;
            margin-left: 0;

            > .row .col-12 {
                padding: 0;
            }
        }

        .img-box {
            margin-bottom: 15px;

            &__a {
                height: 160px;
                background-color: #FFFFFF;
            }

            &__a > img {
                height: 160px;
                border-radius: 5px;
                object-fit: cover;
            }
        }
    }
</style>

<style lang="scss" scoped>

    .wizard .wizard__body .notice-for-repairer-files table.b-table .file-checkbox {
        width: 35px;
        min-width: 35px;
        padding-right: 0px;
    }

    .wizard .wizard__body .notice-for-repairer-files table.b-table .file-checkbox label {
        margin-right: 0px;
    }

    .wizard .wizard__body .notice-for-repairer-files table.b-table .file-name {
        width: 99%;
        padding-left: 0px;
    }

    .wizard .wizard__body .notice-for-repairer-files table.b-table .file-status {
        width: 250px;
        min-width: 250px;
    }

    .claimlinq-notice-for-repairer-body ::v-deep .wizard__steps {
        width: 100%;
    }

    .claimlinq-notice-for-repairer-body ::v-deep .wizard__body {
    }

    .claimlinq-notice-for-repairer-body ::v-deep .wizard__body .notice-for-repairer-step-1 {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .claimlinq-notice-for-repairer-body ::v-deep .wizard__body.vgw-mobile {
        width: 100% !important;
    }

    @media screen and (max-width: 730px) {
        .claimlinq-notice-for-repairer-body ::v-deep .wizard__body .notice-for-repairer-step-1 {
            /* width: 98vw;
            margin: 0; */
        }
    }

    .wizard .wizard__body table.b-table tr th,
    .wizard .wizard__body table.b-table tr td {
        /*padding: 13px 15px;*/
        padding-left: 15px;
        padding-right: 15px;
    }

    .wizard .wizard__body table.b-table tr th {
        background-color: rgba(27, 30, 56, 0.07) !important;
    }

    .grey-color {
        opacity: 0.6;
    }

    .claimlinq-notice-for-repairer-body .notice-for-repairer .header-check {
        /*padding: 0.75rem;*/
        width: 35px;
        min-width: 35px;
    }

    .claimlinq-notice-for-repairer-body .notice-for-repairer .header-name {
        line-height: 35px;
    }

    .V3 .claimlinq-notice-for-repairer-body > .claim-block {
        padding: 0px;
    }

    .V3 .claimlinq-notice-for-repairer-body .claim-block {
        padding: 0px;
    }

    /* file upload -- hide icon and text */
    .claimlinq-notice-for-repairer-body ::v-deep .dropify-message i,
    .claimlinq-notice-for-repairer-body ::v-deep .dropify-message .drop-down-text,
    .claimlinq-notice-for-repairer-body ::v-deep .dropify-message .text-or {
        opacity: 0;
    }

    /* Notice Header -- reference .auth-header modified */
    .V3 .claimlinq-notice-for-repairer-header {
        position: relative;
        width: 100%;
        /* padding-right: 42px; */
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(27, 30, 56, 0.1);
        margin-bottom: 25px;
        padding-top: 25px;
    }

    .V3 .claimlinq-notice-for-repairer-header .close {
        position: absolute;
        right: 3px;
        top: 23px;
        width: 16px;
        height: 16px;
        display: block;
        cursor: pointer;
        font-size: 32px;
        color: #000000;
    }

    .claimlinq-logo {
        text-transform: uppercase;
        line-height: 43px;
        letter-spacing: 1px;
        font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2rem;
    }

    .claimlinq-logo.claimlinq-logo--large {
        font-size: 32px;
    }

    .vh-center {
        margin: 50% auto;
    }

    @media screen and (min-width: 512px) {
        .vh-center {
            margin: calc(50% - 125px) auto;
        }
    }
</style>